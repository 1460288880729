/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import bell from '../../assets/icons/bell.png'
import { Button } from '../atoms/button'
import { formatDate } from '../../lib/datetime'
import { useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Dropdown } from '../atoms/dropdown'
import { NotificationData } from '../../types'
import { notifications } from '../../assets/data/notifications'

const StyledBellIcon = styled.img`
    width: 1.5rem;
    height: 1.5rem;
`

const StyledNotificationRow = styled.div`
    padding: 0.5rem 1rem;

    border-bottom: 1px solid ${({ theme }) => theme.color.grey2};

    &:hover {
        background-color: ${({ theme }) => theme.color.grey1};
    }

    &:first-of-type {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }

    &:last-of-type {
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom: none;
    }
`

const StyledNotificationTitle = styled.h3``
const StyledNotificationText = styled.p``
const StyledNotificationDate = styled.div`
    color: ${({ theme }) => theme.color.grey5};
    /* small */
    font-size: 0.75rem;
`

const NotificationRow = ({ title, message, date }: NotificationData) => (
    <StyledNotificationRow>
        <StyledNotificationTitle>{title}</StyledNotificationTitle>
        <StyledNotificationText>{message}</StyledNotificationText>
        <StyledNotificationDate>{formatDate(date)}</StyledNotificationDate>
    </StyledNotificationRow>
)

type DropdownContentProps = {
    notifications: NotificationData[]
    shouldLoadOnScroll: boolean
    loadMore: () => void
}
const DropdownContent = ({ notifications, shouldLoadOnScroll, loadMore }: DropdownContentProps) =>
    notifications.length === 0 ? (
        <StyledNotificationRow>No notifications yet.</StyledNotificationRow>
    ) : (
        <InfiniteScroll scrollableTarget='notif-dropdown' loader={<p>loading...</p>} next={loadMore} hasMore={shouldLoadOnScroll} dataLength={notifications.length}>
            {notifications.map((notification, idx) => (
                <NotificationRow key={idx} {...notification} />
            ))}
        </InfiniteScroll>
    )

export const NotificationsDropdown = () => {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)

    const loadMore = () => {
        // TODO: load more notifications
        console.log('load more')
    }

    console.log(dropdownRef)

    const handleBlur = (e: React.FocusEvent) => {
        // Give browser time to focus the next element
        setTimeout(() => {
            // Check if the new focused element is a child of the original container
            if (!dropdownRef.current || !dropdownRef.current.contains(document.activeElement)) {
                // Close the dropdown
                setIsOpen(false)
            }
        }, 0)
    }

    // TODO: has more data
    const hasMore = false

    // Ensure that the notifications don't include notifs from the future and
    // are in reverse chronological order (newest at the top)
    const now = new Date()
    const data = notifications //
        .filter((notif) => notif.date <= now)
        .sort((a, b) => b.date.getTime() - a.date.getTime())

    return (
        <div>
            <Dropdown ref={dropdownRef} onBlur={handleBlur} id='notif-dropdown' type='sidebar' isOpen={isOpen}>
                <DropdownContent notifications={data} loadMore={loadMore} shouldLoadOnScroll={hasMore && isOpen} />
            </Dropdown>
            <Button size='icon' color='icon' onClick={() => null} onFocus={() => setIsOpen(true)} onBlur={handleBlur}>
                <StyledBellIcon src={bell} alt='Notifications' />
            </Button>
        </div>
    )
}
