import styled from '@emotion/styled'
import { useAuth } from '../contexts/auth-provider'
import { Logo } from '../components/atoms/logo'
import { StyledInputLabelWrapper, inputStyle } from '../components/common/input-common'
import { useState } from 'react'
import { Button } from '../components/atoms/button'
import { useToast } from '../contexts/toast-provider'

const Container = styled.div`
    background-color: ${({ theme }) => theme.color.grey1};
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
`

const ContentCard = styled.div`
    background-color: white;
    border-radius: 2rem;

    width: 50vw;
    display: flex;
    padding: 4rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
`

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
`
const StyledInput = styled.input`
    ${inputStyle}
`
const StyledHeader = styled.h1`
    margin: 0;
    font-size: 2rem;
`

export const Login = () => {
    const { login } = useAuth()
    const triggerToast = useToast()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const onSubmit = () => {
        if (!email || !password) {
            triggerToast({ message: 'Email and password are required' })
            return
        }

        const u = process.env.REACT_APP_BASE_URL + '/auth/login'
        login({ email, password })
    }

    return (
        <Container>
            <ContentCard>
                <Logo />
                <StyledHeader>Login</StyledHeader>

                {/* so the user can press enter to submit */}
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        onSubmit()
                    }}
                >
                    <FormContainer>
                        <StyledInputLabelWrapper>
                            <label htmlFor='email'>Email</label>
                            <StyledInput id='first-name' type='text' value={email} onChange={(e) => setEmail(e.target.value)} />
                        </StyledInputLabelWrapper>

                        <StyledInputLabelWrapper>
                            <label htmlFor='password'>Password</label>
                            <StyledInput name='password' type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                        </StyledInputLabelWrapper>

                        <Button color='blue' size='med' onClick={onSubmit}>
                            Login
                        </Button>
                    </FormContainer>
                </form>
            </ContentCard>
        </Container>
    )
}
