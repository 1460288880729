/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import { Footer } from '../components/common/footer'
import { Navbar } from '../components/common/navbar'
import { NotificationBanner } from '../components/common/notification-banner'
import { theme } from '../theme/theme'
import { WaitlistOccupancyCard } from './Waitlist/waitlist-occupancy-card'
import { WaitlistAddCard } from './Waitlist/waitlist-add-card'
import { useMemo } from 'react'
import { notifications } from '../assets/data/notifications'

const StyledPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
`

const StyledBody = styled.div`
    background-color: ${({ theme }) => theme.color.grey1};
    /* Make body fill remaining space */
    flex: 1 1 auto;
`

const StyledPageColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 4rem 0;
`

const StyledHeaderText = styled.div`
    color: ${theme.color.grey5};
    font-size: 2rem;
    font-weight: 500;
`

const RefreshBtnContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding: 1rem 1.5rem;
    align-self: stretch;
`

export const WaitlistPage = () => {
    // Show the latest notification on the banner (which is not in the future)
    const latestNotification = useMemo(() => {
        const now = new Date()
        return notifications //
            .filter((notif) => notif.date < now) // future notifications
            .sort((a, b) => (b.date < a.date ? -1 : 1))[0]
    }, [])

    return (
        <StyledPageContainer>
            <Navbar />
            <NotificationBanner show={true}>
                {/* <div>We’re at the end of the day! Don’t forget to do a system refresh to clear things up for the backend folks!</div>
                <Button size='small' type='blue' onClick={() => null}>
                    Refresh
                </Button> */}
                {latestNotification.message}
            </NotificationBanner>

            <StyledBody>
                <StyledPageColumn>
                    <StyledHeaderText>Manage Waitlist</StyledHeaderText>
                    <WaitlistOccupancyCard />
                    <WaitlistAddCard />
                </StyledPageColumn>
                {/* <RefreshBtnContainer>
                <Button size='lg' type='blue' onClick={() => null}>
                    System Refresh
                </Button>
            </RefreshBtnContainer> */}
            </StyledBody>

            <Footer />
        </StyledPageContainer>
    )
}
