import { atom } from 'recoil'

// Stores auth token in global state, and in session storage as a side effect
export const authToken = atom({
    key: 'auth-token',
    default: sessionStorage.getItem('token'),
    effects: [
        ({ onSet }) => {
            onSet((newValue) => {
                if (newValue) {
                    sessionStorage.setItem('token', newValue)
                } else {
                    sessionStorage.removeItem('token')
                }
            })
        },
    ],
})
