/** @jsxImportSource @emotion/react */

// This file is for defining some global element styles. These styles may be
// applied to any component and should be used to ensure that the styles are
// consistent (DRY!)

import { css } from '@emotion/react'
import { theme } from './theme'

/* Default body styles */

export const globalStyles = css`
    /* Default body styles */
    body {
        font-size: 0.875rem;
        color: ${theme.color.grey5};
    }
`

export const unstyleLink = css`
    text-decoration: none;
    color: inherit;
`
