import { useApi } from './api'

export const useAuthApi = () => {
    const api = useApi()

    const login = (email: string, password: string) => api.post('/auth/login', { email, password })
    const logout = () => api.delete('/auth/logout')

    return { login, logout }
}
