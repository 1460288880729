/** @jsxImportSource @emotion/react */
import './index.css'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { WaitlistPage } from './pages/Waitlist'
import { Global } from '@emotion/react'
import { globalStyles } from './theme/styles'
import { Providers } from './app/providers'
import { Login } from './pages/Login'
import { ReactElement } from 'react'
import { useRecoilState } from 'recoil'
import { authToken } from './recoil/auth-state'

const unauthorizedPath = '/login'

type RequireAuthProps = {
    children: ReactElement
    unauthorizedPath: string
}
const RequireAuth = ({ children, unauthorizedPath }: RequireAuthProps) => {
    const [token] = useRecoilState(authToken)
    if (!token) {
        // User not authenticated
        return <Navigate to={unauthorizedPath} replace />
    }
    return children
}

export const App = () => (
    <>
        <Global styles={globalStyles} />
        <BrowserRouter>
            <Providers>
                <Routes>
                    <Route path='/login' element={<Login />}></Route>
                    <Route
                        path='/'
                        element={
                            <RequireAuth unauthorizedPath={unauthorizedPath}>
                                <WaitlistPage />
                            </RequireAuth>
                        }
                    ></Route>
                    <Route path='*' element={<h1>404 Not Found</h1>}></Route>
                </Routes>
            </Providers>
        </BrowserRouter>
    </>
)

export default App
