import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { authToken } from '../recoil/auth-state'
import { useEffect } from 'react'
import { useToast } from '../contexts/toast-provider'

const BASE_URL = process.env.REACT_APP_BASE_URL ?? 'http://localhost:8080'

const api = axios.create({
    baseURL: BASE_URL,
    timeout: 1000,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
})

export const useApi = () => {
    const [token, setToken] = useRecoilState(authToken)
    const navigate = useNavigate()
    const triggerToast = useToast()

    useEffect(() => {
        if (token) {
            api.defaults.headers.common['Authorization'] = `Bearer ${token}`
        } else {
            delete api.defaults.headers.common['Authorization']
        }
    }, [token])

    api.interceptors.response.use(
        (config) => config,
        (err) => {
            if (typeof err.response.data === 'string') {
                triggerToast({ message: 'Server error: ' + err.response.data })
            }

            if (err.response.status === 401 || err.response.status === 403) {
                // Clear invalid token
                setToken(null)
                navigate('/login', { replace: true })
            }

            return Promise.reject(err)
        }
    )

    return api
}
