// Format date according to the timeIn format on the table
// Here are some examples of the format:
// 7:28 AM
// Nov 3, 9:19 PM
// Dec 1, 2022, 7:28 AM
export const formatDate = (date: Date) => {
    const today = new Date()

    const isThisYear = date.getFullYear() === today.getFullYear()
    const isThisMonth = isThisYear && date.getMonth() === today.getMonth()
    const isToday = isThisMonth && date.getDate() === today.getDate()

    return date.toLocaleString('en-US', {
        year: isThisYear ? undefined : 'numeric',
        // Always show day and month at the same time (neither alone)
        month: isToday ? undefined : 'short',
        day: isToday ? undefined : 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    })
}

// Format date for use with the "datetime-local" input type (allows entering date and time)
export const formatDateForDatetimeLocalInput = (date: Date) => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')

    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`

    return formattedDate
}
