import { css } from '@emotion/react'
import { theme } from '../../theme/theme'
import styled from '@emotion/styled'

export const inputStyle = css`
    border-radius: 0.5rem;
    border: 1px solid ${theme.color.grey2};
    padding: 0.5rem 1rem;

    color: ${theme.color.grey5};
    font-family: ${theme.font.family};
`

export const StyledInputLabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`
