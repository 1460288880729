import { useAuthApi } from '../api/auth'

export const useAuthActions = () => {
    const api = useAuthApi()

    const login = (email: string, password: string) => api.login(email, password)
    const logout = () => api.logout()

    return { login, logout }
}
