import styled from '@emotion/styled'
import { useGuestDispatch } from '../../contexts/guest-provider'
import { SearchBar } from '../../components/guest-table/search-bar'
import { DropdownButton } from '../../components/atoms/dropdown-button'
import { Button } from '../../components/atoms/button'
import { useToast } from '../../contexts/toast-provider'
import { Option } from 'react-dropdown'
import { useTableData } from '../../contexts/table-data-provider'
import { FilterOption, defaultFilterOption, filterOptions } from '../../hooks/filterGuests'
import { SortOption, defaultSortOption, sortOptions } from '../../hooks/sortGuests'
import { useEffect } from 'react'
import { useGuestApi } from '../../api/guests'
import { GuestStatus } from '../../types'

const StyledActionBar = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-self: stretch;
`

const StyledActionBarRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    // in case it wraps
    flex-wrap: wrap;
    gap: 0.5rem;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        justify-content: center;
    }
`

const StyledRow = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        justify-content: center;
    }
`

const StyledSearchContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

export const ActionBar = () => {
    const { processedGuests: guests, setSortBy, setFilterBy, searchQuery, clearSearch } = useTableData()
    const guestDispatch = useGuestDispatch()
    const triggerToast = useToast()
    const { setGuestStatuses, deleteGuests } = useGuestApi()

    const selectedGuests = guests.filter((guest) => guest.selected)

    // Cleanup on unmount: clear search, sort, and filter
    useEffect(() => {
        return () => {
            clearSearch()
            setSortBy(defaultSortOption)
            setFilterBy(defaultFilterOption)
        }
    }, [])

    const onClickBulkDelete = () => {
        const previousSelectedGuests = [...selectedGuests]
        triggerToast({
            message: `${selectedGuests.length} guest entr${selectedGuests.length === 1 ? 'y' : 'ies'} deleted`,
            onTrigger: () => {
                // Dispatch on frontend first so that the UI updates immediately
                guestDispatch({
                    type: 'remove',
                    payload: selectedGuests.map((guest) => guest.id),
                })
            },
            onUndo: () => {
                // Load re-adds the guests to the table
                guestDispatch({
                    type: 'load',
                    payload: previousSelectedGuests,
                })
            },
            onCommit: () => {
                deleteGuests(selectedGuests.map((g) => g.id))
            },
        })
    }

    // Creates a function that sets the status of the selected guests to the given status
    const onClickSetStatus = (status: GuestStatus, displayStatus: string) => {
        return () => {
            const previousSelectedGuests = [...selectedGuests]
            triggerToast({
                message: `${selectedGuests.length} guests set as ${displayStatus}}`,
                onTrigger: () => {
                    // Dispatch on frontend first so that the UI updates immediately
                    guestDispatch({
                        type: 'set',
                        payload: selectedGuests.map((guest) => ({ ...guest, status })),
                    })
                },
                onUndo: () => {
                    // Revert the guests to before the action. TODO: race condition?
                    guestDispatch({
                        type: 'set',
                        payload: previousSelectedGuests,
                    })
                },
                onCommit: () => {
                    setGuestStatuses(
                        status,
                        selectedGuests.map((g) => g.id)
                    )
                },
            })
        }
    }

    const onClickSetInShelter = onClickSetStatus('in_shelter', 'In Shelter')
    const onClickSetWaitlisted = onClickSetStatus('waitlisted', 'Waitlisted')
    const onClickSetIdle = onClickSetStatus('idle', 'Idle')

    const onClickCancelSelection = () => guestDispatch({ type: 'deselectAll' })

    const onChangeFilter = (value: Option) => {
        setFilterBy(value.value as FilterOption)
    }
    const onChangeSort = (value: Option) => {
        setSortBy(value.value as SortOption)
    }
    const onClickResetSearch = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        clearSearch()
    }

    let searchPreview = searchQuery.slice(0, 10)
    if (searchPreview.length < searchQuery.length) {
        searchPreview += '...'
    }

    const searchHintString = `${guests.length} result${guests.length !== 1 ? 's' : ''} found for "${searchPreview}"  `

    const contextButtonsDisabled = selectedGuests.length === 0

    return (
        <StyledActionBar>
            <StyledActionBarRow>
                <StyledSearchContainer>
                    <SearchBar />
                    {searchQuery && (
                        <div>
                            <span>{searchHintString}</span>
                            <a href='#' onClick={onClickResetSearch}>
                                Reset
                            </a>
                        </div>
                    )}
                </StyledSearchContainer>
                <StyledRow>
                    <DropdownButton labelText='View' options={filterOptions} onChange={onChangeFilter} />
                    <DropdownButton labelText='Sort By' options={sortOptions} onChange={onChangeSort} />
                </StyledRow>
            </StyledActionBarRow>

            <StyledRow>
                <StyledRow>
                    <Button color='grey' size='small' onClick={onClickSetInShelter} disabled={contextButtonsDisabled}>
                        Set to In Shelter
                    </Button>
                    <Button color='grey' size='small' onClick={onClickSetWaitlisted} disabled={contextButtonsDisabled}>
                        Set to Waitlisted
                    </Button>
                    <Button color='grey' size='small' onClick={onClickSetIdle} disabled={contextButtonsDisabled}>
                        Set to Idle
                    </Button>
                </StyledRow>
                <StyledRow>
                    <Button color='grey' size='small' onClick={onClickBulkDelete} disabled={contextButtonsDisabled}>
                        Delete {selectedGuests.length} entr{selectedGuests.length != 1 ? 'ies' : 'y'}
                    </Button>
                    <Button color='grey' size='small' onClick={onClickCancelSelection} disabled={contextButtonsDisabled}>
                        Deselect All
                    </Button>
                </StyledRow>
            </StyledRow>
        </StyledActionBar>
    )
}
