/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import { ChangeEvent, useEffect, useState } from 'react'
import { Checkbox } from '../atoms/Checkbox'
import { Button } from '../atoms/button'
import { formatDateForDatetimeLocalInput } from '../../lib/datetime'
import PhoneInput from 'react-phone-number-input/input'
import { useGuestDispatch } from '../../contexts/guest-provider'
import { GuestFormInfo } from '../../types'
import { inputStyle } from '../common/input-common'
import { StyledInputLabelWrapper } from '../common/input-common'
import { useToast } from '../../contexts/toast-provider'
import { useGuestApi } from '../../api/guests'

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
`

const StyledRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        gap: 1rem;
    }
`

const StyledInput = styled.input`
    ${inputStyle}

    // Width specified so that the datetime input doesn't resize when it's disabled
    width: 12rem;
`

const StyledInputDisabled = styled(StyledInput)`
    background-color: ${({ theme }) => theme.color.grey1};
    color: ${({ theme }) => theme.color.grey5};
`

const StyledPhoneInput = styled(PhoneInput)`
    ${inputStyle}
    width: 12rem;
`

const StyledSubmitButton = styled(Button)`
    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        align-self: center;
    }
`

export const NewGuestForm = () => {
    const guestDispatch = useGuestDispatch()
    const { addGuest } = useGuestApi()

    const triggerToast = useToast()

    const [currentGuestData, setCurrentGuestData] = useState<GuestFormInfo>({
        firstName: '',
        lastName: '',
        phone: '',
        timeIn: formatDateForDatetimeLocalInput(new Date()),
        useCurrentTime: true,
    })

    const update = (key: keyof GuestFormInfo) => (e: ChangeEvent<HTMLInputElement>) => {
        console.log('update', key, e.target.value)
        setCurrentGuestData((data) => ({ ...data, [key]: e.target.value }))
    }

    const updatePhone = (value: string) => {
        setCurrentGuestData((data) => ({ ...data, phone: value }))
    }

    const toggleUseCurrentTime = () => {
        setCurrentGuestData((data) => ({ ...data, useCurrentTime: !currentGuestData.useCurrentTime }))
    }

    const setTimeInToNow = () => {
        setCurrentGuestData((data) => ({ ...data, timeIn: formatDateForDatetimeLocalInput(new Date()) }))
    }

    // If useCurrentTime is true, update timeIn in realtime
    useEffect(() => {
        let interval: NodeJS.Timer | undefined
        let timeout: NodeJS.Timeout | undefined

        const cleanup = () => {
            clearTimeout(timeout)
            clearInterval(interval)
        }

        if (currentGuestData.useCurrentTime) {
            setTimeInToNow()

            // Calculate difference between now and the next minute
            const now = new Date()
            const nextMinute = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes() + 1)
            const delay = nextMinute.getTime() - now.getTime()

            // Start updating once hit the next minute
            timeout = setTimeout(() => {
                // Update timeIn every second
                interval = setInterval(() => {
                    setTimeInToNow()
                }, 1000)
            }, delay)
        } else {
            cleanup()
        }

        return cleanup
    }, [currentGuestData.useCurrentTime])

    const onSubmit = async () => {
        const { firstName, lastName, phone, timeIn, useCurrentTime } = currentGuestData

        if (!firstName || !lastName || !timeIn) {
            console.log('Missing required fields')
            const missingFields = []
            if (!firstName) {
                missingFields.push('First Name')
            }
            if (!lastName) {
                missingFields.push('Last Name')
            }
            if (!timeIn) {
                missingFields.push('Time In')
            }

            triggerToast({
                message: `Missing required field(s): ${missingFields.join(', ')}`,
            })
            return
        }

        const newGuest = await addGuest({
            firstName,
            lastName,
            phone: phone || '',
            time: useCurrentTime || !timeIn ? new Date().toISOString() : new Date(timeIn).toISOString(),
            status: 'waitlisted',
        })
        // clear form, unless there was an error
        if (!newGuest) return
        guestDispatch({ type: 'new', payload: newGuest })

        setCurrentGuestData((old) => ({
            firstName: '',
            lastName: '',
            phone: '',
            timeIn: formatDateForDatetimeLocalInput(new Date()),
            useCurrentTime: old.useCurrentTime,
        }))
    }

    return (
        <StyledContainer>
            <StyledRow>
                <StyledInputLabelWrapper>
                    <label htmlFor='first-name'>First Name</label>
                    <StyledInput id='first-name' type='text' value={currentGuestData.firstName} onChange={update('firstName')} />
                </StyledInputLabelWrapper>
                <StyledInputLabelWrapper>
                    <label htmlFor='last-name'>Last Name</label>
                    <StyledInput id='last-name' type='text' value={currentGuestData.lastName} onChange={update('lastName')} />
                </StyledInputLabelWrapper>
            </StyledRow>

            <StyledRow>
                <StyledInputLabelWrapper>
                    <label htmlFor='phone-number'>Phone Number (optional)</label>
                    <StyledPhoneInput defaultCountry='CA' id='phone-number' value={currentGuestData.phone} onChange={updatePhone} />
                </StyledInputLabelWrapper>

                <StyledInputLabelWrapper>
                    <label htmlFor='time-in'>Time In</label>
                    {currentGuestData.useCurrentTime ? ( //
                        <StyledInputDisabled id='time-in' type='datetime-local' disabled aria-disabled value={currentGuestData.timeIn} onChange={update('timeIn')} />
                    ) : (
                        <StyledInput id='time-in' type='datetime-local' value={currentGuestData.timeIn} onChange={update('timeIn')} />
                    )}

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.5rem' }}>
                        <Checkbox id='use-current-time' checked={currentGuestData.useCurrentTime} onClick={toggleUseCurrentTime} />
                        <label htmlFor='use-current-time'>Use Current Time</label>
                    </div>
                </StyledInputLabelWrapper>
            </StyledRow>

            <StyledSubmitButton color='blue' size='med' onClick={onSubmit}>
                Submit
            </StyledSubmitButton>
        </StyledContainer>
    )
}
