/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'
import { forwardRef } from 'react'

const StyledDropdownContainer = styled.div`
    position: absolute;
    z-index: 2;

    background-color: white;
    border: 1px solid ${({ theme }) => theme.color.grey2};
    border-radius: 0.25rem;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
`

const StyledSidebarContainer = styled(StyledDropdownContainer)`
    position: fixed;

    overflow: scroll;
    width: 20rem;
    max-height: 80vh;

    right: 1rem;
    top: 4rem;
`

type DropdownType = 'sidebar' | 'dropdown'

const componentMap = {
    sidebar: StyledSidebarContainer,
    dropdown: StyledDropdownContainer,
}

type DropdownProps = {
    id?: string
    onBlur?: (e: React.FocusEvent<HTMLDivElement>) => void
    isOpen: boolean
    type: DropdownType
    children?: React.ReactNode
    // pass through css to container
    css?: SerializedStyles
}
export const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(function Dropdown({ id, onBlur, isOpen, css, type, children }: DropdownProps, ref) {
    const StyledComponent = componentMap[type]

    return (
        <StyledComponent ref={ref} onBlur={onBlur} tabIndex={1} id={id} style={isOpen ? undefined : { display: 'none' }} css={css}>
            {children}
        </StyledComponent>
    )
})
