import { useMemo, useState } from 'react'
import Fuse from 'fuse.js'
import { GuestInstance } from '../types'

export const useSearchGuests = (guests: GuestInstance[]) => {
    const [searchValue, setSearchValue] = useState<string>('')
    // Actual search query (not just what's in the input)
    const [searchQuery, setSearchQuery] = useState<string>('')

    const fuse = useMemo(() => new Fuse(guests, { keys: ['firstName', 'lastName'] }), [guests])

    const matches = useMemo(
        () =>
            searchQuery === '' //
                ? guests
                : fuse.search(searchQuery).map((match) => match.item),
        [guests, searchQuery]
    )

    // TODO: debounced continual search
    const doSearch = () => {
        console.log('searching for ', searchValue)
        return setSearchQuery(searchValue)
    }

    const clearSearch = () => {
        setSearchValue('')
        setSearchQuery('')
    }

    return {
        searchResult: matches,
        searchValue,
        searchQuery,
        setSearchValue,
        doSearch,
        clearSearch,
    }
}
