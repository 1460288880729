import { NotificationData } from '../../types'

// All notifications to be shown on frontend. Not stored in database right now -
// for the sake of the mvp!
//
// The notifications will be shown sorted by date, with the most recent at the
// top as well as being displayed in the banner.
//
// Notifications will only be shown if the date is in the past!!!
//
// When you add a new notification, the message can be either just a string or
// it can be a JSX element. Don't apply styles here though so that the
// notifications still look nice whether they're in the banner or the dropdown.
export const notifications: NotificationData[] = [
    {
        title: 'Extreme Weather Response',
        message: (
            <span>
                The extreme weather response is now in effect.{' '}
                <a href='https://victoriahomelessness.ca/extreme-weather-response/' target='_blank' rel='noreferrer'>
                    Click here to learn more
                </a>
            </span>
        ),
        // NOTE: months are 0-indexed!! so 11 is december
        date: new Date(2023, 11, 1),
    },
]
