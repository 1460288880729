/** @jsxImportSource @emotion/react */
import { GuestTable } from '../../components/guest-table/guest-table'
import styled from '@emotion/styled'
import { StyledContentCard, StyledHeaderText } from './common'
import { ActionBar } from './action-bar'
import { useGlobalData } from '../../contexts/global-data-provider'

const StyledGreyBox = styled.div`
    background-color: ${({ theme }) => theme.color.grey1};
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
`

const StyledCapacityBox = styled(StyledGreyBox)`
    border: 1px solid ${({ theme }) => theme.color.grey2};
    flex-direction: column;
    padding: 0.25rem 1.5rem;
    gap: 0.25rem;
`

const StyledHeaderInfoRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
`

export const WaitlistOccupancyCard = () => {
    const { capacity, capacityFilled } = useGlobalData()
    return (
        <StyledContentCard>
            <StyledHeaderInfoRow>
                <StyledHeaderText>Shelter Occupancy</StyledHeaderText>
                <StyledCapacityBox>
                    <div>Capacity</div>
                    <div>{typeof capacity !== 'number' ? '-/-' : `${capacityFilled}/${capacity}`}</div>
                </StyledCapacityBox>
            </StyledHeaderInfoRow>

            <ActionBar />
            <GuestTable />
        </StyledContentCard>
    )
}
