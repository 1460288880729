import { useState } from 'react'
import { GuestInstance } from '../types'

// It's not the best way to store full strings like this but whatever it's fast
// and I'm in a rush
// Default is first by time in, then by status (so status is grouped at top)
export type SortOption = 'Default' | 'Time In' | 'First Name' | 'Last Name'

export const sortOptions: SortOption[] = ['Default', 'Time In', 'First Name', 'Last Name']
export const defaultSortOption: SortOption = sortOptions[0]

const statusSortOrder = {
    in_shelter: 0,
    waitlisted: 1,
    idle: 2,
}

export const useSortGuests = () => {
    const [sortBy, setSortBy] = useState<SortOption>(defaultSortOption)

    return {
        setSortBy,
        sortBy,
        sortPredicate: (a: GuestInstance, b: GuestInstance) => {
            switch (sortBy) {
                case 'Default':
                    // First sort by time in, then by status
                    return a.status === b.status ? (a.timeIn < b.timeIn ? -1 : 1) : statusSortOrder[a.status] - statusSortOrder[b.status]
                case 'Time In':
                    return a.timeIn < b.timeIn ? -1 : 1
                case 'First Name':
                    return a.firstName < b.firstName ? -1 : 1
                case 'Last Name':
                    return a.lastName < b.lastName ? -1 : 1
            }
        },
    }
}
