import styled from '@emotion/styled'
import { Button } from '../components/atoms/button'
import undoIcon from '../assets/icons/undo-white.png'
import closeIcon from '../assets/icons/close-white.svg'
import { ReactNode, createContext, useContext, useEffect, useState } from 'react'

const PopupContainer = styled.div`
    color: white;
    background: ${({ theme }) => theme.color.blue1};
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.25);

    position: fixed;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);

    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
`
const PopupButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
`

const StyledText = styled.div``

const StyledUndoButton = styled(Button)`
    height: 100%;
    display: flex;
    align-items: center;
`

const StyledXButton = styled(Button)`
    &:hover {
        background-color: ${({ theme }) => theme.color.transparentWhite};
    }
`

const StyledXIcon = styled.img`
    width: 1.5rem;
    height: 1.5rem;
`

const StyledUndoIcon = styled.img`
    width: 1.25rem;
    height: 1.25rem;
`

//TODO: Move!!
const popupDurationSeconds = 5

// Show a message with the last action performed with a button to undo it.
// onUndo: callback to undo the action. Clientside only!!
// commit: commit the data to the server and close the popup. This also happens automatically after popupDurationSeconds.
type ToastPopupType = {
    message: string
    isUndoable: boolean
    onClickUndo: () => void
    onClickClose: () => void
    onMouseOver?: () => void
    onMouseLeave?: () => void
}
const ToastPopup = ({ message, isUndoable, onClickUndo, onClickClose, onMouseOver, onMouseLeave }: ToastPopupType) => (
    <PopupContainer onPointerEnter={onMouseOver} onPointerLeave={onMouseLeave}>
        <StyledText>{message}</StyledText>

        <PopupButtonContainer>
            {isUndoable && (
                <StyledUndoButton size='small' color='blue' onClick={onClickUndo}>
                    <div>Undo</div>
                    <StyledUndoIcon src={undoIcon} alt='Undo' />
                </StyledUndoButton>
            )}

            <StyledXButton size='icon' color='icon' onClick={onClickClose}>
                <StyledXIcon src={closeIcon} />
            </StyledXButton>
        </PopupButtonContainer>
    </PopupContainer>
)

// The toast action can either be a simple message or an undoable action.
export type ToastType =
    | {
          message: string
      }
    | {
          message: string
          // Function to trigger the immediately visible frontend result of the
          // action. Happens immediately when the action is triggered.
          onTrigger: () => void
          // Reverts the changes done by onTrigger!! Also, undoing prevents
          // onCommit from running.
          onUndo: () => void
          // Commits the action to the server. Happens when the toast is closed
          // or expired, but not if it's undone.
          onCommit: () => void
      }

// goal: to be able to say a new undoable action has been performed from any component.
export type ToastContextType = (action: ToastType) => void

// Stores global state, allowing any component to trigger a toast message with
// an optional undoable action.
const ToastContext = createContext<ToastContextType | null>(null)

// Wrap the app (or at least whatever pages need it) in this provider to allow
// any component to trigger a message with optional undoable action using the
// useToast hook. The popup is open whenever there is an undoable
// action (and vice versa).
export const ToastProvider = ({ children }: { children: ReactNode }) => {
    // Managing window openness
    const [commitTimeout, setCommitTimeout] = useState<NodeJS.Timeout | undefined>(undefined)

    // Current action being displayed that may be undone
    const [curToast, setCurToast] = useState<ToastType | null>(null)
    const isUndoable = curToast !== null && 'onUndo' in curToast

    // Close popup and actually make the request to the server
    const closeAndCommit = () => {
        clearTimeout(commitTimeout)

        if (curToast !== null) {
            if (isUndoable) curToast.onCommit()
            setCurToast(null)
        }
    }

    // Close popup and don't do the action
    const onClickUndo = () => {
        clearTimeout(commitTimeout)

        if (curToast !== null) {
            if (isUndoable) curToast.onUndo()
            setCurToast(null)
        }
    }

    // Note: only use in useEffect!
    const startTimer = () => {
        // Dismiss popup after timeout
        const timeout = setTimeout(() => {
            closeAndCommit()
        }, popupDurationSeconds * 1000)

        setCommitTimeout(timeout)
    }

    const stopTimer = () => {
        clearTimeout(commitTimeout)
    }

    // Automatically dismiss the popup after a certain amount of time and apply
    // the action if the user doesn't undo it. Also apply the action if the
    // component unmounts (handled in startTimer).
    useEffect(() => {
        // Clear the timeout whenever the component is mounted to start fresh
        stopTimer()
        if (curToast !== null) {
            // If there's a toast on mount, make sure the timer's going
            startTimer()
        }

        return () => {
            // Clear the timeout if the component unmounts. Note that it doesn't
            // apply the action! When the x button is clicked, the action is
            // applied specifically.
            stopTimer()
        }
    }, [curToast])

    const onTrigger = (action: ToastType) => {
        if (curToast !== null) {
            // Commit the previous action before starting a new one
            closeAndCommit()
        }

        if ('onTrigger' in action) {
            // Trigger the visible results of the action immediately
            action.onTrigger()
        }

        // TODO: backup to session storage??
        //TODO: show warning when navigating away from page with uncommitted action!
        setCurToast(action)
    }

    const isPopupOpen = curToast !== null

    return (
        <ToastContext.Provider value={onTrigger}>
            {isPopupOpen && (
                <ToastPopup //
                    message={curToast.message}
                    isUndoable={isUndoable}
                    onClickUndo={onClickUndo}
                    onClickClose={closeAndCommit}
                    onMouseOver={stopTimer}
                    onMouseLeave={startTimer}
                />
            )}
            {children}
        </ToastContext.Provider>
    )
}

export const useToast = () => {
    const context = useContext(ToastContext)
    if (context === null) {
        throw new Error('UseUndoConfirmation must be used within a UndoConfirmationProvider')
    }
    return context
}
