import searchIcon from '../../assets/icons/search.svg'
import styled from '@emotion/styled'
import { useTableData } from '../../contexts/table-data-provider'

const StyledSearchBar = styled.div`
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    background: white;
    border: 1.5px solid ${({ theme }) => theme.color.grey2};
`

const StyledSearchInput = styled.input`
    border: none;
    margin: 0.25rem 1rem;
    background: none;
`

const StyledSearchButtonContainer = styled.div`
    background-color: ${({ theme }) => theme.color.grey1};

    border-left: 1.5px solid ${({ theme }) => theme.color.grey2};

    border-radius: 0 0.4rem 0.4rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
`

const StyledSearchButton = styled.button`
    display: flex; // for centering
    border: none;
    background: none;
    cursor: pointer;
    height: 100%;
    padding: 0rem 0.5rem;
    border-radius: 0 0.4rem 0.4rem 0;
    align-items: center;

    &:hover {
        background-color: ${({ theme }) => theme.color.grey2};
    }
`

const StyledSearchIcon = styled.img`
    width: 1.3125rem;
    height: 1.3125rem;
`

export const SearchBar = () => {
    const { searchValue, setSearchValue, doSearch } = useTableData()

    return (
        /* The form is to allow searching by hitting enter */
        <form
            onSubmit={(e) => {
                e.preventDefault()
                doSearch()
            }}
        >
            <StyledSearchBar>
                <StyledSearchInput type='text' placeholder='Search' value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                <StyledSearchButtonContainer>
                    <StyledSearchButton onClick={doSearch}>
                        <StyledSearchIcon src={searchIcon} />
                    </StyledSearchButton>
                </StyledSearchButtonContainer>
            </StyledSearchBar>
        </form>
    )
}
