import styled from '@emotion/styled'

const StyledNotificationBanner = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100%;
    color: white;
    background-color: ${({ theme }) => theme.color.blue1};
    padding: 0.5rem 0;
    gap: 0.625rem;

    // selector for anchor tags inside this component to override default anchor style
    a {
        color: white;
    }
`

type NotificationBannerProps = {
    children: React.ReactNode
    show: boolean
}
export const NotificationBanner = ({ children, show }: NotificationBannerProps) => {
    if (!show) return null

    return <StyledNotificationBanner>{children}</StyledNotificationBanner>
}
