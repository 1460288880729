import styled from '@emotion/styled'
import { StyledTableRow } from './guest-table-styles'

const Container = styled(StyledTableRow)`
    background-color: ${({ theme }) => theme.color.grey1};
    border-radius: 0.75rem 0.75rem 0 0;
`

const Cell = styled.div`
    padding: 0.75rem 1.5rem;
`

export const TableHeader = () => (
    <Container>
        <Cell></Cell>
        <Cell>Name</Cell>
        <Cell>Time In</Cell>
        <Cell>Status</Cell>
    </Container>
)
