import { useState } from 'react'
import { GuestInstance } from '../types'

export type FilterOption = 'All' | 'In Shelter' | 'Waitlisted' | 'Idle'

export const filterOptions: FilterOption[] = ['All', 'In Shelter', 'Waitlisted', 'Idle']
export const defaultFilterOption: FilterOption = filterOptions[0]

export const useFilterGuests = () => {
    const [filterBy, setFilterBy] = useState<FilterOption>('All')

    return {
        setFilterBy,
        filterBy,
        filterPredicate: (g: GuestInstance) => {
            switch (filterBy) {
                case 'All':
                    return true
                case 'In Shelter':
                    return g.status === 'in_shelter'
                case 'Waitlisted':
                    return g.status === 'waitlisted'
                case 'Idle':
                    return g.status === 'idle'
            }
        },
    }
}
