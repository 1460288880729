import { createContext, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthActions } from '../actions/auth'
import { useRecoilState } from 'recoil'
import { authToken } from '../recoil/auth-state'

export type AuthContextType = {
    login: (data: LoginData) => void
    logout: () => void
}

type LoginData = {
    email: string
    password: string
}

const checkLogin = (data: any): data is string => {
    return data && typeof data == 'string'
}

const AuthContext = createContext<AuthContextType | null>(null)

//https://blog.logrocket.com/complete-guide-authentication-with-react-router-v6/
export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [token, setToken] = useRecoilState(authToken)
    const navigate = useNavigate()

    const auth = useAuthActions()

    // call this function when you want to authenticate the user
    const login = async ({ email, password }: LoginData) => {
        try {
            const u = (process.env.REACT_APP_BASE_URL || 'http://localhost:8080') + '/auth/login'
            const re = await fetch(u, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            })
            const token = await re.text()
            if (re.status === 200) {
                setToken(token)
                navigate('/')
            } else {
                console.log(re.status)
            }
        } catch (e) {
            console.error(e)
        }
        /*
        auth.login(email, password)
            .then((res) => res.data)
            .then((data) => {
                if (!checkLogin(data)) {
                    console.log('login failed')
                    return
                }
                console.log('login data: ', data)
                setToken(data)
                navigate('/')
            })
            .catch((error) => {
                console.log('could not log in')
                console.log(error)
            })
        */
    }

    // call this function to sign out logged in user
    const logout = async () => {
        try {
            const r = await auth.logout()
            if (r.status !== 204) {
                const msg = await r.data()
                console.error(msg)
            }
        } catch (e) {
            console.error(e)
        } finally {
            setToken(null)
            navigate('/login', { replace: true })
        }
    }

    const value = useMemo(
        () => ({
            login,
            logout,
        }),
        [token]
    )
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    const context = useContext(AuthContext)
    if (context === null) {
        throw new Error('useAuth must be used within an AuthProvider')
    }
    return context
}
