/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import { GuestID, GuestInstance, GuestStatus } from '../../types'
import { StatusBadge } from '../atoms/status-badge'
import { Tooltip } from 'react-tooltip'
import { StyledTableRow } from './guest-table-styles'
import { Checkbox } from '../atoms/Checkbox'
import { formatDate } from '../../lib/datetime'
import { Button } from '../atoms/button'
import { css } from '@emotion/react'
import { theme } from '../../theme/theme'
import { useEffect, useRef } from 'react'

const StyledCell = styled.div`
    padding: 0.75rem 1.5rem;
    align-self: center;
`

const StyledCenteredCell = styled(StyledCell)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const StyledNameCell = styled(StyledCell)`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
`

const statusDropdownStyle = css`
    .status-tooltip {
        color: ${theme.color.grey5};
        font-size: 0.9rem;

        width: 8rem;

        background-color: white;
        border: 1px solid ${theme.color.grey2};
        border-radius: 0.25rem;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
        padding: 0 1rem;
    }
`

const statusButtonStyle = css`
    border-radius: 4px;
    padding: 0;
    margin-bottom: 0.5rem;
    width: 100%;
    &:hover {
        background-color: unset;
    }
    &:not(last-of-type) {
        margin-top: 0.5rem;
    }
`

type TableRowProps = {
    guest: GuestInstance
    rowSelected: boolean
    rowId: number
    setRowSelected: (guestId: GuestID, selected: boolean) => void
    setGuestStatus: (guest: GuestInstance, status: GuestStatus) => void
    waitlistPlace?: number
    shouldScrollTo: boolean
}
export const TableRow = ({ guest, rowSelected, rowId, setRowSelected, setGuestStatus, waitlistPlace, shouldScrollTo }: TableRowProps) => {
    const guestFullName = `${guest.firstName} ${guest.lastName}`
    const guestInfoTooltipId = 'info-tooltip' + rowId
    const statusTooltipId = 'status-tooltip' + rowId

    const ref = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (shouldScrollTo && ref.current) {
            console.log('should scroll to: ', guest.id)
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }, [shouldScrollTo, ref.current])

    const onCheckboxClick = () => setRowSelected(guest.id, !rowSelected)

    return (
        <StyledTableRow css={statusDropdownStyle} ref={ref}>
            <Tooltip className='status-tooltip' id={guestInfoTooltipId} clickable openOnClick closeOnScroll closeOnEsc opacity={1}>
                <div>{guestFullName}</div>
                <div>Phone: {guest.phone || '(none)'}</div>
            </Tooltip>
            <Tooltip id={statusTooltipId} className='status-tooltip' clickable openOnClick closeOnScroll closeOnEsc opacity={1}>
                <Button color='icon' css={statusButtonStyle} onClick={() => setGuestStatus(guest, 'waitlisted')}>
                    <StatusBadge status='waitlisted' />
                </Button>
                <Button color='icon' css={statusButtonStyle} onClick={() => setGuestStatus(guest, 'in_shelter')}>
                    <StatusBadge status='in_shelter' />
                </Button>
                <Button color='icon' css={statusButtonStyle} onClick={() => setGuestStatus(guest, 'idle')}>
                    <StatusBadge status='idle' />
                </Button>
            </Tooltip>

            <StyledCell>
                <Checkbox checked={rowSelected} onClick={onCheckboxClick} />
            </StyledCell>
            <StyledNameCell data-tooltip-id={guestInfoTooltipId}>{guestFullName}</StyledNameCell>
            <StyledCell>{formatDate(new Date(guest.timeIn))}</StyledCell>
            <StyledCenteredCell>
                <StatusBadge data-tooltip-id={statusTooltipId} status={guest.status}>
                    {waitlistPlace !== undefined && <span> (#{waitlistPlace}) </span>}
                </StatusBadge>
            </StyledCenteredCell>
        </StyledTableRow>
    )
}
