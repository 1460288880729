import styled from '@emotion/styled'
import checkboxChecked from '../../assets/icons/checkbox-checked.svg'
import checkboxUnchecked from '../../assets/icons/checkbox-unchecked.svg'

const StyledCheckboxImg = styled.img`
    cursor: pointer;
    width: 1.4rem;
    height: 1.4rem;
`

const UnstyleButton = styled.button`
    display: inherit;
    background-color: inherit;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
`

type CheckboxProps = {
    checked: boolean
    onClick?: () => void
    id?: string
}
export const Checkbox = ({ checked, onClick, id }: CheckboxProps) => (
    <UnstyleButton id={id} onClick={onClick}>
        <StyledCheckboxImg src={checked ? checkboxChecked : checkboxUnchecked} />
    </UnstyleButton>
)
