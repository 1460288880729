/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { unstyleLink } from '../../theme/styles'
import { Link } from 'react-router-dom'

const StyledContainer = styled.div`
    display: flex;
    padding: 2rem 8rem;
    justify-content: space-between;
    align-items: center;
    background-color: black;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        padding: 2rem 4rem;
    }
`

const StyledItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
`

const textStyle = css`
    ${unstyleLink}
    color: white;
    font-size: 0.875rem;
`

export const Footer = () => {
    return (
        <StyledContainer>
            <StyledItemContainer>
                <div css={textStyle}>Contact Us</div>
                <a href='mailto:support@inso.ca' css={textStyle}>
                    support@inso.ca
                </a>
            </StyledItemContainer>
            {/* <StyledItemContainer>
                <Link to='/terms' css={textStyle}>
                    Terms & Conditions
                </Link>
                <Link to='/privacy' css={textStyle}>
                    Privacy Policy
                </Link>
            </StyledItemContainer> */}
        </StyledContainer>
    )
}
