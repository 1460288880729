/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme } from '../../theme/theme'
import { ComponentPropsWithRef } from 'react'

const StyledButtonBase = styled.button`
    display: flex;
    cursor: pointer;
    border-radius: 8px;
    border: none;
    background-color: inherit;
    gap: 0.5rem;
`

const StyledButtonWhite = styled(StyledButtonBase)`
    color: black;
    background-color: white;
    border: 1.5px solid;
    border-color: ${({ theme }) => theme.color.grey3};

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
        background-color: ${({ theme }) => theme.color.grey1};
    }
`

const StyledButtonBlue = styled(StyledButtonBase)`
    color: white;
    background-color: ${({ theme }) => theme.color.blue1};
    border: 1.5px solid;
    border-color: white;

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
        background-color: ${({ theme }) => theme.color.blue2};
    }
`

const StyledButtonGrey = styled(StyledButtonBase)`
    background-color: ${({ theme }) => theme.color.grey1};
    color: ${({ theme }) => theme.color.grey5};
    border: 1px solid;
    border-color: ${({ theme }) => theme.color.grey3};
`

const StyledButtonIcon = styled(StyledButtonBase)`
    border-radius: 50%;
    padding: 0.5rem;
    gap: 0;

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
        background-color: ${({ theme }) => theme.color.grey1};
        color: green;
    }
`

export type ButtonColor = 'white' | 'blue' | 'grey' | 'icon'
export type ButtonSize = 'small' | 'med' | 'lg' | 'icon'

const buttonComponentMap: { [K in ButtonColor]: any } = {
    white: StyledButtonWhite,
    blue: StyledButtonBlue,
    grey: StyledButtonGrey,
    icon: StyledButtonIcon,
}

const buttonSizeMap: { [K in ButtonSize]: SerializedStyles } = {
    small: css`
        padding: 0.25rem 1rem;
    `,
    med: css`
        padding: 0.5rem 1.5rem;
    `,
    lg: css`
        padding: 1rem 1.5rem;
    `,
    icon: css``,
}

const disabledCommon = css`
    cursor: default;
`

const buttonDisabledMap: { [K in ButtonColor]: SerializedStyles } = {
    white: css`
        ${disabledCommon}
        background-color: ${theme.color.grey2};
        border-color: ${theme.color.grey2};
        color: ${theme.color.grey4};
    `,
    blue: css`
        ${disabledCommon}
        background-color: ${theme.color.blue2};
    `,
    grey: css`
        ${disabledCommon}
        background-color: ${theme.color.grey2};
        border-color: ${theme.color.grey2};
        color: ${theme.color.grey4};
    `,
    icon: css`
        ${disabledCommon}
        background-color: ${theme.color.grey2};
        border-color: ${theme.color.grey2};
        color: ${theme.color.grey4};
    `,
}

type ButtonProps = ComponentPropsWithRef<typeof StyledButtonBase> & {
    color: ButtonColor
    size?: ButtonSize
}
export const Button = ({ size = 'med', color, ...props }: ButtonProps) => {
    const { disabled } = props
    const ButtonComponent = buttonComponentMap[color]
    const buttonSize = buttonSizeMap[size]

    const css = [buttonSize, disabled && buttonDisabledMap[color]]

    return <ButtonComponent css={css} {...props} />
}
