import styled from '@emotion/styled/macro'
import { ReactNode } from 'react'
import { GuestStatus } from '../../types'

const Dot = styled.span`
    display: inline-block;
    width: 0.5625rem;
    height: 0.5625rem;
    border-radius: 50%;
    margin-right: 0.5rem;
`

const StyledBaseContainer = styled.div`
    user-select: none;
    cursor: pointer;

    display: inline-block;
    width: 100%;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    border-radius: 0.5rem;
    white-space: nowrap;
`
const StyledInShelterContainer = styled(StyledBaseContainer)`
    background-color: ${({ theme }) => theme.color.green1};
    color: ${({ theme }) => theme.color.green2};

    &:hover {
        background-color: ${({ theme }) => theme.color.green3};
    }

    ${Dot} {
        background-color: ${({ theme }) => theme.color.green2};
    }
`
const StyledWaitlistedContainer = styled(StyledBaseContainer)`
    background-color: ${({ theme }) => theme.color.red1};
    color: ${({ theme }) => theme.color.red2};

    &:hover {
        background-color: ${({ theme }) => theme.color.red3};
    }

    ${Dot} {
        background-color: ${({ theme }) => theme.color.red2};
    }
`
const StyledIdleContainer = styled(StyledBaseContainer)`
    background-color: ${({ theme }) => theme.color.grey1};
    color: ${({ theme }) => theme.color.grey5};

    &:hover {
        background-color: ${({ theme }) => theme.color.grey2};
    }

    ${Dot} {
        background-color: ${({ theme }) => theme.color.grey5};
    }
`

// Badge showing whether a person is "in shelter" or "waitlisted"

type StatusBadgeProps = {
    status: GuestStatus
    children?: ReactNode
}
export const StatusBadge = ({ status, children, ...rest }: StatusBadgeProps) => {
    if (status === 'in_shelter') {
        return (
            <StyledInShelterContainer {...rest}>
                <Dot />
                <span>In Shelter</span>
                {children}
            </StyledInShelterContainer>
        )
    } else if (status === 'waitlisted') {
        return (
            <StyledWaitlistedContainer {...rest}>
                <Dot />
                <span>Waitlisted</span>
                {children}
            </StyledWaitlistedContainer>
        )
    } else if (status === 'idle') {
        return (
            <StyledIdleContainer {...rest}>
                <Dot />
                <span>Idle</span>
                {children}
            </StyledIdleContainer>
        )
    } else {
        return null
    }
}
