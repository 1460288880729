/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import ReactDropdown, { Option } from 'react-dropdown'
import chevron_down from '../../assets/icons/chevron.svg'
import { css } from '@emotion/react'
import { theme } from '../../theme/theme'

const StyledContainer = styled.div`
    background-color: ${({ theme }) => theme.color.grey1};
    border: 1px solid ${({ theme }) => theme.color.grey2};
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    gap: 0.5rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    user-select: none;

    &:hover {
        background-color: ${({ theme }) => theme.color.grey2};
    }
`

const StyledLabel = styled.span`
    color: ${({ theme }) => theme.color.blue1};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`

const StyledArrow = styled.img`
    width: 0.625rem;
    height: 0.625rem;
`

const StyledArrowRight = styled(StyledArrow)`
    transform: rotate(-90deg);
`

const menuStyle = css`
    .Dropdown-control {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 0.5rem;
        cursor: pointer;
        user-select: none;
    }

    .Dropdown-menu {
        position: absolute;
        background-color: ${theme.color.grey1};
        border: 1px solid ${theme.color.grey2};
        border-radius: 0.25rem;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    }

    .Dropdown-option {
        padding: 0.5rem 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        cursor: pointer;

        border-bottom: 1px solid ${theme.color.grey2};

        &:hover {
            background-color: ${theme.color.grey2};
        }

        &:first-of-type {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem;
        }

        &:last-of-type {
            border-bottom-left-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
            border-bottom: none;
        }
    }
`

export type DropdownOptionType = string | Option

type DropdownButtonProps = {
    labelText: string
    // The first option is default
    options: DropdownOptionType[]
    onChange: (value: Option) => void
}
export const DropdownButton = ({ labelText, options, onChange }: DropdownButtonProps) => {
    return (
        <StyledContainer>
            <StyledLabel>{labelText}:</StyledLabel>
            <ReactDropdown css={menuStyle} options={options} value={options[0]} onChange={onChange} arrowOpen={<StyledArrow src={chevron_down} />} arrowClosed={<StyledArrowRight src={chevron_down} />} />
        </StyledContainer>
    )
}
