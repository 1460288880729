/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import { Button } from '../atoms/button'
import { NotificationsDropdown } from './notifications-dropdown'
import { useAuth } from '../../contexts/auth-provider'
import { Logo } from '../atoms/logo'

const StyledNavbarWrapper = styled.div`
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 4rem;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        padding: 1rem 2rem;
    }
`

const StyledNavbarItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        gap: 0.5rem;
    }
`

export const Navbar = () => {
    const { logout } = useAuth()
    return (
        <StyledNavbarWrapper>
            <Logo />
            <StyledNavbarItem>
                <NotificationsDropdown />

                <Button color='white' onClick={logout}>
                    Sign Out
                </Button>
            </StyledNavbarItem>
        </StyledNavbarWrapper>
    )
}
