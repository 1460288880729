/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import InfiniteScroll from 'react-infinite-scroll-component'
import { TableHeader } from './table-header'
import { TableRow } from './table-row'
import { GuestData, GuestID, GuestInstance, GuestStatus } from '../../types'
import { useGuestDispatch } from '../../contexts/guest-provider'
import { BarLoader } from 'react-spinners'
import { theme } from '../../theme/theme'
import { useEffect, useState } from 'react'
import { wait } from '../../lib/promise'
import { useTableData } from '../../contexts/table-data-provider'
import { defaultFilterOption } from '../../hooks/filterGuests'
import { useGlobalData } from '../../contexts/global-data-provider'
import { useToast } from '../../contexts/toast-provider'
import { useGuestApi } from '../../api/guests'

const StyledHintText = styled.p`
    margin: 1rem;
    text-align: center;
`

const mockData: GuestInstance[] = [
    // { id: 0, firstName: '0Jane', lastName: 'Smith', phone: '', timeIn: new Date().valueOf(), inShelter: true, selected: false },
    // { id: 1, firstName: '1Jane', lastName: 'Smith', phone: '', timeIn: Date.UTC(2023, 11, 1), inShelter: true, selected: false },
    // { id: 2, firstName: '2Jane', lastName: 'Smith hdhqwiuh wiquddiqwiodjwiodjwiodj', phone: '', timeIn: Date.UTC(2023, 4, 1), inShelter: true, selected: false },
    // { id: 3, firstName: '3Jane', lastName: 'Smith', phone: '', timeIn: Date.UTC(2021, 1, 1), inShelter: true, selected: false },
    // { id: 4, firstName: '4Jane', lastName: 'Smith', phone: '', timeIn: Date.UTC(2021, 1, 1), inShelter: true, selected: false },
    // { id: 5, firstName: '5Jane', lastName: 'Smith', phone: '', timeIn: Date.UTC(2021, 1, 1), inShelter: true, selected: false },
    // { id: 6, firstName: '6Jane', lastName: 'Smith', phone: '', timeIn: Date.UTC(2021, 1, 1), inShelter: true, selected: false },
]

// const getMockUser = (id: GuestID): GuestInstance => {
//     return { id, firstName: id + 'Jane', lastName: 'Smith', phone: '', timeIn: Date.UTC(2021, 2, id), inShelter: false, selected: false }
// }

const TableBody = () => {
    const { totalGuests, guestEvent, latestGuestModified, processedGuests: guests, sortBy, filterBy, searchValue } = useTableData()
    const guestDispatch = useGuestDispatch()
    const { setCapacity } = useGlobalData()
    const { getGuests, setGuestStatuses } = useGuestApi()
    const triggerToast = useToast()

    const [loading, setLoading] = useState(false)

    const setRowSelected = (guestId: GuestID, selected: boolean) => {
        console.log('set selected', guestId, selected)
        if (selected) {
            guestDispatch({ type: 'select', payload: [guestId] })
        } else {
            guestDispatch({ type: 'deselect', payload: [guestId] })
        }
    }

    const setGuestStatus = (guest: GuestInstance, status: GuestStatus) => {
        const guestFullName = `${guest.firstName} ${guest.lastName}`
        const newStatusText = status === 'in_shelter' ? 'in shelter' : status === 'waitlisted' ? 'waitlisted' : 'idle'

        triggerToast({ message: `${guestFullName} has been set as ${newStatusText}.` })

        setGuestStatuses(status, [guest.id])
        guestDispatch({
            type: 'set',
            payload: [{ ...guest, status }],
        })
    }

    const fetchMoreData = async () => {
        // TODO: for now, loading all guests at once. Later, use server-side
        // pagination
        // const count = 200
        // const offset = guests.length
        setLoading(true)

        const guestsPromise = getGuests()
        // Makes sure the loading bar doesn't disappear too quickly
        const [newGuests] = await Promise.all([guestsPromise, wait(500)]).finally(() => setLoading(false))

        guestDispatch({ type: 'load', payload: newGuests.guests })
        setCapacity(newGuests.capacity)
        // setCapacityFilled(newGuests.capacityFilled)
    }

    // This is a lil hack to make sure we don't fetch data twice on initial load
    // in development mode. Just react things
    let thisRenderLoading = false

    useEffect(() => {
        // Fetch initial data
        if (thisRenderLoading) return

        fetchMoreData()

        thisRenderLoading = true
        return () => {
            guestDispatch({ type: 'reset' })
        }
    }, [])

    // TODO: get from api
    const hasMoreData = false

    const waitlistedGuests = guests.filter((guest) => guest.status === 'waitlisted').sort((a, b) => (a.timeIn < b.timeIn ? -1 : 1))

    // Note: only showing waitlist place when sorted by time in! Otherwise it's not accurate
    const calculateWaitlistPlace = (guest: GuestData) => {
        return waitlistedGuests.findIndex((g) => g.id === guest.id) + 1
    }

    const Hint = () => {
        if (loading) return null

        if (totalGuests === 0 && !hasMoreData) {
            return <StyledHintText>There are no guests yet. Add a guest using the form below!</StyledHintText>
        } else if (hasMoreData && guests.length < 6) {
            //  This is a safeguard. When there aren't enough guests loaded in
            //  at first to fill up the scroll container, the scrollbar doesn't
            //  appear. Loading more guests normally only happens on scroll.
            //  This shouldn't be necessary though - just load at least enough
            //  to fill up the container from the start!
            return null
            // return (
            //     <Button
            //         type='blue'
            //         size='med'
            //         onClick={fetchMoreData}
            //         css={css`
            //             margin: 1rem;
            //         `}
            //     >
            //         Load more guests
            //     </Button>
            // )
        } else if (guests.length === 0 && (filterBy === defaultFilterOption || searchValue === '')) {
            return <StyledHintText>{"No guests found. Try clearing the search or setting 'View' to 'All'"}</StyledHintText>
        } else return null
    }

    return (
        <>
            {/* Fixed height loader container so it doesn't shift things around */}
            <div style={{ height: '2px' }}>
                <BarLoader width='100%' height={2} speedMultiplier={0.75} color={theme.color.blue1} loading={loading} />
            </div>

            <InfiniteScroll height='50vh' dataLength={guests.length} next={fetchMoreData} hasMore={hasMoreData} loader={null}>
                {guests.map((guest, idx) => {
                    const showWaitlistPlace = (sortBy === 'Default' || sortBy === 'Time In') && guest.status === 'waitlisted'

                    return (
                        <TableRow //
                            key={idx}
                            guest={guest}
                            rowId={idx}
                            waitlistPlace={showWaitlistPlace ? calculateWaitlistPlace(guest) : undefined}
                            rowSelected={guest.selected}
                            setRowSelected={setRowSelected}
                            setGuestStatus={setGuestStatus}
                            shouldScrollTo={guestEvent === 'scrollLatest' && latestGuestModified === guest.id}
                        />
                    )
                })}

                <Hint />
            </InfiniteScroll>
        </>
    )
}

const TableWrapper = styled.div`
    border-radius: 0.75rem;
    border: 1px solid ${({ theme }) => theme.color.grey1};
    width: 100%;
`

export const GuestTable = () => (
    <TableWrapper>
        <TableHeader />
        <TableBody />
    </TableWrapper>
)
