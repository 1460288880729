import { ThemeProvider } from '@emotion/react'
import { AuthProvider } from '../contexts/auth-provider'
import { ToastProvider } from '../contexts/toast-provider'
import { GuestProvider } from '../contexts/guest-provider'
import { theme } from '../theme/theme'
import { TableDataProvider } from '../contexts/table-data-provider'
import { GlobalDataProvider } from '../contexts/global-data-provider'
import { RecoilRoot } from 'recoil'

export const Providers = ({ children }: { children: React.ReactNode }) => (
    <ThemeProvider theme={theme}>
        <RecoilRoot>
            <ToastProvider>
                <AuthProvider>
                    <GuestProvider>
                        <GlobalDataProvider>
                            <TableDataProvider>{children}</TableDataProvider>
                        </GlobalDataProvider>
                    </GuestProvider>
                </AuthProvider>
            </ToastProvider>
        </RecoilRoot>
    </ThemeProvider>
)
