import styled from '@emotion/styled'
import logo from '../../assets/icons/logo.png'

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    user-select: none;
`
const StyledLogoIcon = styled.img`
    width: 1.625rem;
    height: 1.625rem;
`
const StyledLogoText = styled.div`
    color: black;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`

export const Logo = () => (
    <Container>
        <StyledLogoIcon src={logo} alt='Logo' />
        <StyledLogoText>INSO Housing</StyledLogoText>
    </Container>
)
