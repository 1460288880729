import { createContext, useContext, useMemo, useState } from 'react'
import { useGuests } from './guest-provider'

type GlobalDataContextType = {
    capacity: number | null
    capacityFilled: number | null
    setCapacity: (capacity: number) => void
}

const GlobalDataContext = createContext<GlobalDataContextType | null>(null)

// Should have used redux :(
// Put any other global data here
export const GlobalDataProvider = ({ children }: { children: React.ReactNode }) => {
    const [capacity, setCapacity] = useState<number | null>(null)
    // TODO: ignoring since all on frontend
    // const [capacityFilled, setCapacityFilled] = useState<number | null>(null)

    const { guests } = useGuests()
    const capacityFilled = useMemo(() => guests.filter((guest) => guest.status === 'in_shelter').length, [guests])

    return (
        <GlobalDataContext.Provider
            value={{
                capacity,
                capacityFilled,
                setCapacity,
            }}
        >
            {children}
        </GlobalDataContext.Provider>
    )
}

export const useGlobalData = () => {
    const context = useContext(GlobalDataContext)
    if (context === null) {
        throw new Error('useGlobalData must be used within a GlobalDataProvider')
    }
    return context
}
