import { Dispatch, createContext, useContext, useReducer } from 'react'
import { GuestData, GuestID, GuestInstance } from '../types'

// Goal: store selected users. So can add from checkboxes and consume from wherever, eg action bar

export type GuestContextType = {
    guests: GuestInstance[]
    // To allow scrolling to a newly created guest
    guestEvent: 'scrollLatest' | 'none'
    latestGuestModified: GuestID | null
}

export type GuestActionType =
    | { type: 'new'; payload: GuestData }
    | {
          type: 'load'
          payload: GuestData[]
      }
    | {
          type: 'set'
          payload: GuestInstance[]
      }
    | {
          type: 'remove' | 'select' | 'deselect'
          payload: GuestID[]
      }
    | { type: 'deselectAll' | 'reset' | 'clearEvent' }

const initialState: GuestContextType = {
    guests: [],
    guestEvent: 'none',
    latestGuestModified: null,
}

const GuestContext = createContext<GuestContextType>(initialState)
const GuestDispatchContext = createContext<Dispatch<GuestActionType> | null>(null)

// TODO: at this point I should have just used redux...
const guestsReducer = (state: GuestContextType, action: GuestActionType) => {
    console.log('dispatched: ', action) //TODO: remove
    // log call stack

    const clearGuestEvent = { guestEvent: 'none', latestGuestModified: null } as Omit<GuestContextType, 'guests'>

    switch (action.type) {
        case 'new': {
            if (state.guests.find((guest) => guest.id === action.payload.id)) {
                // Guest already exists
                return state
            }
            return {
                guestEvent: 'scrollLatest',
                latestGuestModified: action.payload.id,
                guests: [...state.guests, { ...action.payload, selected: false }],
            } as GuestContextType
        }

        // Load guests from the database
        case 'load': {
            // Make sure we don't add duplicates. This is because React likes to
            // mess with us and dispatch duplicate actions (in development mode)
            // to make sure our reducer is "pure"

            const existingGuestIds = new Set(state.guests.map((guest) => guest.id))

            const newGuests = action.payload
            const uniqueNewGuests = newGuests //
                .filter((guest) => !existingGuestIds.has(guest.id))
                .map((guest) => ({ ...guest, selected: false }))

            return { ...clearGuestEvent, guests: [...state.guests, ...uniqueNewGuests] } as GuestContextType
        }
        // Update the given guests (by id) with the given data
        case 'set':
            if (action.payload.length === 0) return state
            return {
                // TODO: prevent scrolling to updated guest
                ...clearGuestEvent,
                // guestEvent: 'scrollLatest',
                // end TODO

                latestGuestModified: action.payload[0].id,
                guests: state.guests.map((guest) => {
                    const updatedGuest = action.payload.find((g) => g.id === guest.id)
                    // Don't update if not found
                    return updatedGuest ? updatedGuest : guest
                }),
            } as GuestContextType
        case 'remove':
            return {
                // TODO: prevent scrolling to removed guest
                ...clearGuestEvent,
                // guestEvent: 'scrollLatest',
                // end TODO

                latestGuestModified: action.payload[0],
                guests: state.guests.filter((guest) => !action.payload.includes(guest.id)),
            } as GuestContextType
        case 'select':
            return { ...clearGuestEvent, guests: state.guests.map((guest) => (action.payload.includes(guest.id) ? { ...guest, selected: true } : guest)) }
        case 'deselect':
            return { ...clearGuestEvent, guests: state.guests.map((guest) => (action.payload.includes(guest.id) ? { ...guest, selected: false } : guest)) }
        case 'deselectAll':
            return { ...clearGuestEvent, guests: state.guests.map((guest) => ({ ...guest, selected: false })) }
        case 'clearEvent':
            return { ...state, ...clearGuestEvent } as GuestContextType
        case 'reset':
            return { ...initialState }
    }
}

export const GuestProvider = ({ children }: { children: React.ReactNode }) => {
    const [guests, dispatch] = useReducer(guestsReducer, initialState)

    return (
        <GuestContext.Provider value={guests}>
            <GuestDispatchContext.Provider value={dispatch}>{children}</GuestDispatchContext.Provider>
        </GuestContext.Provider>
    )
}

export const useGuests = () => {
    const context = useContext(GuestContext)
    if (context === null) {
        throw new Error('useSelectedGuests must be used within a GuestProvider')
    }
    return context
}

export const useGuestDispatch = () => {
    const context = useContext(GuestDispatchContext)
    if (context === null) {
        throw new Error('useGuestDispatch must be used within a GuestProvider')
    }
    return context
}
