import { createContext, useContext } from 'react'
import { SortOption, useSortGuests } from '../hooks/sortGuests'
import { FilterOption, useFilterGuests } from '../hooks/filterGuests'
import { useGuests } from './guest-provider'
import { GuestID, GuestInstance } from '../types'
import { useSearchGuests } from '../hooks/searchGuests'

type TableDataState = {
    // Total number of guests, before search or filter
    totalGuests: number
    processedGuests: GuestInstance[]
    guestEvent: 'scrollLatest' | 'none'
    latestGuestModified: GuestID | null
    sortBy: SortOption
    setSortBy: React.Dispatch<React.SetStateAction<SortOption>>
    filterBy: FilterOption
    setFilterBy: React.Dispatch<React.SetStateAction<FilterOption>>
    searchValue: string
    searchQuery: string
    setSearchValue: React.Dispatch<React.SetStateAction<string>>
    doSearch: () => void
    clearSearch: () => void
}

const TableDataContext = createContext<TableDataState | null>(null)

// Handles search, sort, and filtering for the table
type TableDataProviderProps = { children: React.ReactNode }
export const TableDataProvider = ({ children }: TableDataProviderProps) => {
    const { guests, ...guestsCtx } = useGuests()

    const totalGuests = guests.length

    // TODO: search
    const sortGuests = useSortGuests()
    const filterGuests = useFilterGuests()

    // Apply filter, then search, then sort, then finally sort by status to keep all in-shelter guests together
    const filteredGuests = guests.filter(filterGuests.filterPredicate)

    // Search from filtered guests
    const searchGuests = useSearchGuests(filteredGuests)

    searchGuests.searchResult.sort(sortGuests.sortPredicate)

    return (
        <TableDataContext.Provider
            value={{
                totalGuests,
                processedGuests: searchGuests.searchResult,
                ...guestsCtx,
                ...sortGuests,
                ...filterGuests,
                ...searchGuests,
            }}
        >
            {children}
        </TableDataContext.Provider>
    )
}

// Get a list of guests modified by search, filter, and sort params
export const useTableData = () => {
    const context = useContext(TableDataContext)
    if (context === null) {
        throw new Error('useProcessedTableData must be used within a TableDataProvider')
    }
    return context
}
