// Common components

import styled from '@emotion/styled'

export const StyledContentCard = styled.div`
    width: 70vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    background-color: white;
    padding: 2.5rem 4.5rem;
    border-radius: 2rem;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        margin: 2rem 0;
        padding: 2rem 3rem;
    }
`

export const StyledHeaderText = styled.div`
    color: ${({ theme }) => theme.color.grey5};
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`
