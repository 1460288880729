import { Theme } from '@emotion/react'

export const theme: Theme = {
    color: {
        primary: '#888',
        transparentWhite: 'rgba(255, 255, 255, 0.2)',
        grey1: '#f2f2f2',
        grey2: '#d3d3d3',
        grey3: '#C0C0C0',
        grey4: '#888',
        grey5: '#555',
        blue1: '#4d7cf6',
        blue2: '#8cabfb',
        green1: '#ccffe1',
        green2: '#219653',
        green3: '#a9ddbe',
        red1: '#ffd5cc',
        red2: '#bb3a3a',
        red3: '#ecc1b8',
    },
    font: {
        family: 'Graphik Compact',
    },
    breakpoints: {
        sm: '480px',
        md: '720px',
        lg: '1280px',
        xl: '1920px',
    },
}
