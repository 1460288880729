import { useApi } from './api'
import { GuestData, GuestID, GuestStatus } from '../types'
import { ApiGuest } from './api-types'

export const hydrateGuest = (guest: ApiGuest): GuestData => {
    return { ...guest, timeIn: new Date(guest.time) }
}

export const dehydrateGuest = (guest: GuestData): ApiGuest => {
    return { ...guest, time: guest.timeIn.toISOString() }
}

export const useGuestApi = () => {
    const api = useApi()

    // TODO: use "capacity" and "capacityFilled"
    // const getGuests = (count: number, offset: number): Promise<GuestData[]> => {
    const getGuests = (): Promise<{ guests: GuestData[]; capacity: number; capacityFilled: number }> => {
        return api
            .get(
                '/occupants'
                // , {params: { count, offset },}
            )
            .then((res) => {
                if (!res.data.occupants || res.data.occupants.length === 0) {
                    console.log('No guests found')
                    return {
                        guests: [],
                        capacity: res.data.capacity || 0,
                        capacityFilled: res.data.capacityFilled || 0,
                    }
                }

                console.log(res)
                const guests = res.data.occupants.map(hydrateGuest)
                return { guests, capacity: res.data.capacity, capacityFilled: res.data.capacityFilled }
            })
            .catch((err) => {
                console.log(err)
                return { guests: [], capacity: 0, capacityFilled: 0 }
            })
    }

    const addGuest = (guest: Omit<ApiGuest, 'id'>) =>
        api.post('/occupants', guest).then((res) => {
            console.log('added guest', res)
            return hydrateGuest({ ...guest, id: res.data })
        })

    // TODO: backend unfinished?
    const updateGuest = (guest: GuestData) => api.put(`/${guest.id}`, dehydrateGuest(guest))

    const deleteGuests = (ids: GuestID[]) => api.delete('/occupants', { data: { occupants: ids } })

    const setGuestStatuses = (status: GuestStatus, ids: GuestID[]) =>
        api.put('/occupants/checkin', {
            status,
            occupantIDs: ids,
        })

    return { getGuests, addGuest, updateGuest, deleteGuests, setGuestStatuses }
}
